/*
 $(document).on('click', 'follow', function () {
 var $self = $(this);
 var id    = $self.attr('data-id');
 var url   = $self.attr('data-url');

 follow(id, url);
 });

 // [AJAX] Function to like a product
 function follow(id, url) {
 $.ajax({
 url : url,
 type: 'POST',
 data: {
 id: id,
 }
 })
 .done(function (data) {
 location.reload();
 })
 .fail(function () {
 console.log("An error occured while following");
 location.reload();
 });
 }*/

$(document).ready(function() {
	// $(function() {
	// 	if (typeof Storage != 'undefined') {
	// 		if (!localStorage.getItem('done')) {
	// 			setTimeout(function() {
	// 				$('#discountModal').modal('show')
	// 			}, 700)
	// 		}
	// 		localStorage.setItem('done', true)
	// 	}
	// })
})

$(document).on('submit', '#formSendContactEmail', function(e) {
	$.ajaxSetup({
		header: $('meta[name="_token"]').attr('content')
	})
	e.preventDefault(e)

	$.ajax({
		type: 'POST',
		url: '/sendContactEmail',
		data: $(this).serialize(),
		dataType: 'json',
		success: function(data) {
			console.log(data)

			$('#alert-success').fadeIn()
			setTimeout(function() {
				$('#alert-success').fadeOut()
			}, 5000)

			$('#formSendContactEmail').each(function() {
				this.reset()
			})
		},
		error: function(data) {
			$('#alert-error').fadeIn()
			setTimeout(function() {
				$('#alert-error').fadeOut()
			}, 5000)

			console.log(data.responseText)
		}
	})
})
